import React, { FC, useState } from 'react';
import classnames from 'classnames';
import {
  Row,
  Col,
  CardDeck,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';
import styles from './index.module.scss';

import Truncate from 'react-truncate';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faCircleInfo,
  faTriangleExclamation,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons';

import { getUnixTime } from 'date-fns';

// Component
import CardsImage from '../../Cards/CardsImage/CardsImage';
import CardsCompanyLogo from '../../Cards/CardsCompanyLogo/CardsCompanyLogo';
//import UnsubscribeChannelCard from './UnsubscribeChannelCard';

// Libraries
import talksCountByChannelId from '../../../libs/talksCountByChannelId';
import RemoveChannelCard from '../../../components/RemoveChannelCard/RemoveChannelCard'

interface listGrids {
  gridItemName?: string; // What are all the grid item names?@hugo? Lets change this to string literal type
  listStyle?: string; // Can we change this to string literal type as well.
  footLinkTitle: string;
  footLinkUnsubscribe?: boolean;
  footLinkManageChannel?: string;
  footLinkViewChannel?: string;
  gridData: ChannelArray[];
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  type: 'summits' | 'series' | 'channels';
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  clickableCard?: boolean;
  pageType?: string;
  companyLogo?: boolean;
  imgHoverOverlay?: boolean;
  utmParams?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  dataBddSubs?: string;
  dataBddTalksQnt?: string;
  baseUrl?: string;
  priorityStatus?: boolean;
}

export interface ChannelArray {
  id: number;
  title: string;
  description: string;
  organisation: string;
  keywords: string;
  keywordsList: string[];
  strapline: string;
  url: string;
  searchVisibility: string;
  rating: number;
  statistics: Statistics;
  features: Feature[];
  links: Link[];
  featuredCommunications: FeaturedCommunication[];
}

export interface Statistics {
  upcomingCommunications: number;
  liveCommunications: number;
  recordedCommunications: number;
  subscribers: number;
  viewedSeconds: number;
}

export interface Feature {
  name: string;
  enabled: boolean;
  value?: string;
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title?: string;
}

export interface FeaturedCommunication {
  id: number;
  title: string;
  description: string;
  start: string;
  duration: number;
  provider: string;
  thumbnailLink: ThumbnailLink;
  links: FeaturedCommunicationLink[];
}

export interface ThumbnailLink {
  href: string;
  rel: string;
  type: string;
  title: string;
}

export interface FeaturedCommunicationLink {
  href: string;
  rel: string;
  type: string;
  title: string;
}

const ChannelsGridItems: FC<listGrids> = ({
  gridItemName,
  listStyle,
  gridData,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  footLinkTitle,
  footLinkUnsubscribe,
  footLinkManageChannel,
  footLinkViewChannel,
  listLayout,
  cardLayout,
  clickableCard,
  pageType,
  companyLogo,
  imgHoverOverlay,
  utmParams,
  dataBddTitle,
  dataBddDesc,
  dataBddSubs,
  dataBddTalksQnt,
  baseUrl,
  priorityStatus,
}) => {
  const Data = gridData;

  // STATES
  const [unsubscribeCard, setUnsubscribeCard] = useState<object>({});
  const [loading, setLoading] = useState<boolean>(false);
  // END OF STATES

  function getWebcastUrl(featuredCommunications, id) {
    let webcastUrl = '';
    if (featuredCommunications?.length > 0) {
      webcastUrl = `${baseUrl}/webcast/${id}/${featuredCommunications[0]['id']}/${utmParams}`;
    } else {
      webcastUrl = `${baseUrl}/channel/${id}/${utmParams}`;
    }
    return webcastUrl;
  }

  //console.log(Data);
  function setWebcastStatus(featuredCommunications) {
    let webcastStartTime = '';
    let webcastStatus = '';
    let webcastUnixFormat = 0;
    const timeNow = Date.now();

    if (featuredCommunications?.length > 0) {
      // Currently we have no closeTime / Duration or talk status data in the return
      // limiting ability currently to remove live labels

      featuredCommunications.map(({ start }) => {
        // get the talk start time from the channel response
        webcastStartTime = start;
        // Format start time as Unix for convenience of comparisons
        webcastUnixFormat = getUnixTime(new Date(webcastStartTime));
      });
    } else {
      webcastStatus = '';
    }

    // work out the talk status by timestamp comparison
    if (webcastUnixFormat != 0 && webcastUnixFormat > timeNow) {
      webcastStatus = 'upcoming';
    }

    if (webcastUnixFormat != 0 && webcastUnixFormat < timeNow) {
      webcastStatus = 'recorded';
    }

    if (webcastUnixFormat != 0 && webcastUnixFormat === timeNow) {
      // This does not handle the 5 mins to live aspect
      // This does not now when the talk has finished and remove the label
      webcastStatus = 'live';
    }

    return webcastStatus;
  }

  function getChannelImages(linksArray, imageType) {
    let channelLogo;
    linksArray &&
      linksArray.map(({ href, title }) => {
        if (imageType === 'logo' && title === 'logo') {
          channelLogo = href;
        } else {
          if (imageType === 'image' && title === 'preview') {
            channelLogo = href;
          }
        }
      });

    return channelLogo;
  }

  // set a image hover class to enable switching off styles
  // Only really want to set this class if we DO NOT want hover
  // otherwise we might have pass props for all other grid calls.
  let imgHoverOverlayClass = '';
  if (imgHoverOverlay === false) {
    imgHoverOverlayClass = 'no-img-hover';
  } else if (imgHoverOverlay === true) {
    imgHoverOverlayClass = '';
  }

  /**
   * Function to return the URL with the trailing slash(/).
   * @returns URL string
   */
  const addTrailingSlash = (channelURL: string) => {
    const formattedURL = channelURL + '/';
    return formattedURL;
  };

  const unsubscribeLink = (index) => {
    setUnsubscribeCard({
      ...unsubscribeCard,
      [index]: !unsubscribeCard[index],
    });
  };

  return (
    <>
      <Row className={classnames(styles[listLayout], 'g-4')}>
        {Data?.map(
          (
            {
              id,
              title,
              description,
              strapline,
              links,
              url,
              statistics,
              featuredCommunications,
            },
            index
          ) => (
            <Col
              sm={rowColSm}
              md={rowColMd}
              lg={rowColLg}
              xl={rowColXl}
              className={styles['column']}
              key={`ChannelsCol-` + id + index}
              data-section-name={gridItemName}
			  id={`ChannelsCol-`+ pageType + index}
            >
              <CardDeck
                key={`CardDeck-` + id + index}
                className={classnames('h-100')}
              >
                {!!unsubscribeCard[index] && pageType === 'subscribedChannels' && (
                  <Card
                    key={`Card-` + id + index}
                    className={classnames(
                      styles['list-item'],
                      styles[gridItemName],
                      styles[listStyle],
                      styles['ChannelsCard'],
                      styles[cardLayout],
                      styles[setWebcastStatus(featuredCommunications)],
                      styles[imgHoverOverlayClass],
                      styles['card'],
                      styles['unsubscribe-card'],
                      'h-100'
                    )}
                  >
                    <Row
                      style={{ height: '100%' }}
                      className={classnames(
                        styles['cust-row-height'],
                        styles[gridItemName]
                      )}
                    >
                      <Col>
                        <CardBody
                          key={`cardbody-` + id + index}
                          className={styles['cardbody']}
                        >
                          {(() => {
                            if (loading === true ) {
                              return (
                                <CardText
                                  key={`alert-icon` + id + index}
                                  tag="p"
                                  className={styles['alert-icon-wrap']}
                                >
                                  <FontAwesomeIcon
                                    key={`FontAwesomeIcon-` + id}
                                    icon={faSpinner}
                                    spin={true}
                                    size={'1x'}
                                    className={styles['channels-loading']}
                                  ></FontAwesomeIcon>
                                </CardText>
                              );
                            } else {
                              return (
                                <>
                                  <CardText
                                    key={`alert-icon` + id + index}
                                    tag="p"
                                    className={styles['alert-icon-wrap']}
                                  >
                                    <FontAwesomeIcon
                                      key={`FontAwesomeIcon-` + id}
                                      icon={faTriangleExclamation}
                                      className={styles['alert-icon']}
                                    ></FontAwesomeIcon>
                                  </CardText>

                                  <CardText
                                    key={`unsubscribe-title` + id + index}
                                    tag="h2"
                                    className={styles['unsubscribe-title']}
                                  >
                                    Are you sure you want to unsubscribe?
                                  </CardText>

                                  <CardText
                                    key={`unsubscribe-instruction` + id + index}
                                    tag="p"
                                    className={
                                      styles['unsubscribe-instruction']
                                    }
                                  >
                                    You will lose access to all talks from{' '}
                                    {title}?
                                  </CardText>

                                  <CardText
                                    key={`item-link` + id + index}
                                    tag="div"
                                    className={styles['unsubscribe-links']}
                                  >
                                    <CardText
                                      key={`wrap-foot-links` + id + index}
                                      tag="p"
                                      className={styles['link-wrap']}
                                    >
                                      <a
                                       onClick={(e) => {
                                        RemoveChannelCard(
                                         e,
                                         setLoading,
                                         id,
                                         index
                                        );
                                       }}
                                       key={
                                        `footLinkDeleteChannel` + id + index
                                       }
                                       className={styles['delete-channel']}
                                      >
                                        Unsubscribe
                                      </a>
                                      <span
                                        onClick={(e) => unsubscribeLink(index)}
                                        key={`footLinkUnsubscribe` + id + index}
                                        className={
                                          styles['unsubscribe-channel-link']
                                        }
                                      >
                                        Cancel
                                      </span>
                                    </CardText>
                                  </CardText>
                                </>
                              );
                            }
                          })()}
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                )}

                {!unsubscribeCard[index] &&  (
                  <Card
                    key={`Card-` + id + index}
                    className={classnames(
                      styles['list-item'],
                      styles[gridItemName],
                      styles[listStyle],
                      styles['ChannelsCard'],
                      styles[cardLayout],
                      styles[setWebcastStatus(featuredCommunications)],
                      styles[imgHoverOverlayClass],
                      styles['card'],
                      'h-100'
                    )}
                  >
                    <Row
                      xs={1}
                      sm={2}
                      style={{ height: '100%' }}
                      className={classnames(
                        styles['cust-row-height'],
                        styles[gridItemName]
                      )}
                    >
                      {clickableCard === true && (
                        <a
                          className={classnames('stretched-link')}
                          href={`${baseUrl}/channel/${id}/${utmParams}`}
                          aria-label={'View ' + title}
                        ></a>
                      )}

                      <Col className={styles['col-1-item-body']}>
                        <CardBody
                          key={`cardbody-` + id + index}
                          className={styles['cardbody']}
                        >
                          {gridItemName != 'search-channels-item' &&
                            companyLogo != false && (
                              <div
                                key={`companyLogo` + id + index}
                                className={styles['body-comp-logo']}
                              >
                                <a href={`${baseUrl}/channel/${id}/${utmParams}`}>
                                  <CardsCompanyLogo
                                    key={`CardsCompanyLogo-` + id + index}
                                    id={id + index}
                                    href={getChannelImages(links, 'logo')}
                                    title={'Channel Logo'}
                                    width={50}
                                    height={50}
                                    imageType={'logo'}
                                    gridType={'channels'}
                                  />
                                </a>
                              </div>
                            )}

                          <div
                            key={`main-body` + id + index}
                            className={styles['main-body']}
                          >
                            <a href={`${baseUrl}/channel/${id}/${utmParams}`}>
                              <CardTitle
                                tag="h2"
                                className={styles['item-title']}
                                key={`item-title` + id + index}
                                data-bdd={dataBddTitle}
                              >
                                {title}
                              </CardTitle>
                            </a>

                            <div
                              key={`body-header-` + id}
                              className={classnames(
                                styles['body-header'],
                                styles['subs-talks-counts']
                              )}
                            >
                              <CardText>
                                {statistics['subscribers'] && (
                                  <span
                                    key={`subscribers` + id + index}
                                    data-bdd={dataBddSubs}
                                  >
                                    {statistics['subscribers']} subscribers
                                  </span>
                                )}
                                {
                                  <span
                                    key={`totalTalks` + id + index}
                                    data-bdd={dataBddTalksQnt}
                                  >
                                    <span className={styles['text-divider']}>
                                      |
                                    </span>
                                    {talksCountByChannelId(statistics)} talks
                                  </span>
                                }
                              </CardText>
                            </div>

                            <CardText
                              tag="p"
                              className={classnames(styles['card-description'])}
                              key={`card-strapline` + id + index}
                              data-bdd={dataBddDesc}
                            >
                              <Truncate key={`Truncate` + id + index} lines={3}>
                                {strapline}
                              </Truncate>
                            </CardText>

                            {gridItemName === 'search-channels-item' && (
                              <CardText
                                tag="p"
                                className={classnames(
                                  styles['card-description']
                                )}
                                key={`card-description` + id + index}
                              >
                                <Truncate
                                  key={`Truncate` + id + index}
                                  lines={2}
                                >
                                  {description}
                                </Truncate>
                              </CardText>
                            )}

                            <CardText
                              key={`item-link` + id + index}
                              tag="p"
                              className={styles['item-meta']}
                            >
                              {footLinkTitle && (
                                <a
                                  href={`${baseUrl}/channel/${id}/${utmParams}`}
                                  key={`footLinkTitle` + id + index}
                                  className={classnames(
                                   styles['text-link-primary'],
                                   styles['channel-action-link'],
                                   styles['sml']
                                  )}
                                >
                                  {footLinkTitle}{' '}
                                  <FontAwesomeIcon
                                    key={`FontAwesomeIcon-` + id}
                                    icon={faAngleRight}
                                  ></FontAwesomeIcon>
                                </a>
                              )}

                              {footLinkManageChannel &&
                                pageType === 'manageChannels' && (
                                  <a
                                    href={`${baseUrl}/central?channelId=${id}&source=content`}
                                    key={`footLinkManage` + id + index}
                                    className={classnames(
                                     styles['text-link-primary'],
                                     styles['channel-action-link'],
                                     styles['sml']
                                    )}
                                  >
                                    {footLinkManageChannel}{' '}
                                    <FontAwesomeIcon
                                      key={`FontAwesomeIcon-` + id}
                                      icon={faAngleRight}
                                    ></FontAwesomeIcon>
                                  </a>
                                )}

                              {footLinkUnsubscribe &&
                                pageType === 'subscribedChannels' && (
                                  <span
                                    onClick={(e) => unsubscribeLink(index)}
                                    key={`footLinkUnsubscribe` + id + index}
                                    className={
                                      styles['unsubscribe-channel-link']
                                    }
                                  >
                                    Unsubscribe
                                  </span>
                                )}

                              {footLinkViewChannel &&
                                pageType === 'manageChannels' && (
                                  <a
                                    href={`${baseUrl}/channel/${id}/${utmParams}`}
                                    key={`footLinkViewChannel` + id + index}
                                    className={styles['view-channel-link']}
                                  >
                                    {footLinkViewChannel}
                                  </a>
                                )}
                            </CardText>
                          </div>
                        </CardBody>
                      </Col>

                      <Col className={styles['col-2-img']}>
                        <div
                          key={`img-wrap` + id + index}
                          className={classnames(
                            styles['img-wrap'],
                            styles['link-img-hover']
                          )}
                        >
                          {companyLogo === true &&
                            gridItemName === 'search-channels-item' && (
                              <div
                                key={`companyLogo` + id + index}
                                className={styles['body-comp-logo']}
                              >
                                <a href={`${baseUrl}/channel/${id}/${utmParams}`}>
                                  <CardsCompanyLogo
                                    key={`CardsCompanyLogo-` + id + index}
                                    id={id + index}
                                    href={getChannelImages(links, 'logo')}
                                    title={'Channel Logo'}
                                    width={150}
                                    height={150}
                                    gridType={'channels'}
                                  />
                                </a>
                              </div>
                            )}
                          {(() => {
                            if (
                              featuredCommunications?.length > 0 ||
                              talksCountByChannelId(statistics) > 0
                            ) {
                              return (
                                <>
                                  {(gridItemName === 'channel-item' ||
                                    gridItemName ===
                                      'channel-page-channels') && (
                                    <a
                                      href={getWebcastUrl(
                                        featuredCommunications,
                                        id
                                      )}
                                    >
                                      {setWebcastStatus(
                                        featuredCommunications
                                      ) === 'live' && (
                                        <span className={styles['live-label']}>
                                          LIVE NOW
                                        </span>
                                      )}
                                      <CardsImage
                                        key={`CardsImage-` + id + index}
                                        id={id}
                                        href={getChannelImages(
                                          featuredCommunications[0]?.links,
                                          'image'
                                        )}
                                        title={title}
                                        gridType={'webcasts'}
                                        priorityStatus={priorityStatus}
                                      />
                                    </a>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className={styles['no-talks-wrap']}>
                                    <p
                                      className={
                                        styles['no-talks-scheduled-text']
                                      }
                                    >
                                      <FontAwesomeIcon icon={faCircleInfo} /> No
                                      talks scheduled
                                    </p>
                                  </div>
                                </>
                              );
                            }
                          })()}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
              </CardDeck>
            </Col>
          )
        )}
      </Row>
    </>
  );
};

export default ChannelsGridItems;
