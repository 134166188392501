import React, { FC, useState, useEffect } from 'react';
import styles from './index.module.scss';

// Components
import SectionHeading from '../SectionHeadings/SectionHeadings';
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonChannelsGridItems from '../listGridItems/ChannelsGridItems/Skeleton';
import ChannelsGridItems from '../listGridItems/ChannelsGridItems/ChannelsGridItems';

import LoadMore from '../LoadMore/LoadMore';
import getFetch from '../../libs/getFetch';

interface ChannelsProps {
  channelsGrid: ChannelArray[];
  found: number;
  utmParams?: string;
  channelFilterParams?: ChannelFilterParamsArray;
  type?: 'summits' | 'series' | 'channels'; //this isnt strictly required passing in as hardcoded string in callback
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  clickableCard?: boolean;
  footLinkTitle?: string;
  footLinkViewChannel?: string;
  footLinkManageChannel?: string;
  gridItemName?: string;
  companyLogo?: boolean;
  listStyle?: string;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  imgHoverOverlay?: boolean;
  dataBddTitle?: string;
  dataBddDesc?: string;
  dataBddSubs?: string;
  dataBddTalksQnt?: string;
  baseUrl: string;
  priorityStatus?: boolean;
  sectionHeading: true | false;
  headingBlockName?: string;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  skeletonHeadingDescription?: boolean;
  skeletonGridCounter?: number;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  isFirstBlock?: boolean;
}

export interface ChannelFilterParamsArray {
  size?: number;
  bq?: string;
  rank?: string;
  rankClosest?: string;
}

export interface ChannelArray {
  id: number;
  title: string;
  description: string;
  organisation: string;
  keywords: string;
  keywordsList: string[];
  strapline: string;
  url: string;
  searchVisibility: string;
  rating: number;
  statistics: Statistics;
  features: Feature[];
  links: Link[];
  featuredCommunications: FeaturedCommunication[];
}

export interface Statistics {
  upcomingCommunications: number;
  liveCommunications: number;
  recordedCommunications: number;
  subscribers: number;
  viewedSeconds: number;
}

export interface Feature {
  name: string;
  enabled: boolean;
  value?: string;
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title?: string;
}

export interface FeaturedCommunication {
  id: number;
  title: string;
  description: string;
  start: string;
  duration: number;
  provider: string;
  thumbnailLink: ThumbnailLink;
  links: FeaturedCommunicationLink[];
}

export interface ThumbnailLink {
  href: string;
  rel: string;
  type: string;
  title: string;
}

export interface FeaturedCommunicationLink {
  href: string;
  rel: string;
  type: string;
  title: string;
}

const ChannelsGrid: FC<ChannelsProps> = ({
  channelsGrid,
  found,
  utmParams = '',
  channelFilterParams,
  type,
  listLayout,
  cardLayout,
  clickableCard,
  footLinkTitle,
  footLinkViewChannel,
  footLinkManageChannel,
  gridItemName,
  pageType,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  listStyle,
  companyLogo,
  imgHoverOverlay,
  dataBddTitle,
  dataBddDesc,
  dataBddSubs,
  dataBddTalksQnt,
  baseUrl,
  priorityStatus,
  sectionHeading,
  headingBlockName,
  sectionHeadingTitle,
  sectionHeadingDescription,
  skeletonHeadingDescription,
  skeletonGridCounter,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  isFirstBlock,
}) => {
  let defaultSize;

  if (
    channelFilterParams?.['size'] > 0 ||
    channelFilterParams?.['size'] !== undefined
  ) {
    defaultSize = Number(channelFilterParams['size']);
  } else {
    defaultSize = 6;
  }

  const [channelsData, setChannelsData] = useState(channelsGrid);
  const [channelCount, setChannelCount] = useState<number>(defaultSize);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getSummits = async () => {
      setIsLoading(channelsGrid && false);
    };
    getSummits();
  });

  const url = '/api/channels';

  const loadMoreBtn = async () => {
    setShowLoadMoreBtn(true);

    // Fetch new set of talks. We call latestTalks endpoint via backend to avoid CORS issue
    const resJson = await getFetch(baseUrl + url, {
      start: channelCount,
      size: channelFilterParams['size'],
      bq: channelFilterParams['bq'],
      rank: channelFilterParams['rank'],
      rankClosest: channelFilterParams['rankClosest'],
    });

    //Push new set of data into existing array. Using JS spread operator.
    setChannelsData((oldTalksData) => [
      ...oldTalksData,
      ...resJson['channels'],
    ]);

    setChannelCount((prevValue) => prevValue + defaultSize);

    // Turn off loading spinner
    setShowLoadMoreBtn(false);
  };

  // Hide load more if visible is more less or equals to found talks
  let displayLoadMore;
  if (channelCount <= found && channelsGrid?.length > 0) {
    displayLoadMore = true;
  } else {
    displayLoadMore = false;
  }

  return <div className={styles['latest-channels']} data-bdd="channels-grid">

  {(() => {

    if ( sectionHeading == true ) {

      if ( isLoading ) {

       return (

        <SkeletonSectionHeadings
         skeletonHeadingDescription={skeletonHeadingDescription}
         {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
        />
       );

      } else if  ( ChannelsGrid?.length > 0 ) {

       return (

         <SectionHeading
          title={sectionHeadingTitle}
          description={sectionHeadingDescription}
          link={sectionHeadingLink}
          linkTitle={sectionHeadingLinkText}
          headingBlockName={headingBlockName}
          dataBddTitle={sectionHeadingBddTitle}
          dataBddDesc={sectionHeadingBddDesc}
          dataBddLink={sectionHeadingBddLink}
          {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
          />
        );

      } else {
       <></>
      }
     }

   })()}

   {(() => {

     if ( isLoading )  {

       return (
        <SkeletonChannelsGridItems gridCounter={skeletonGridCounter} />
       );

     } else if ( ChannelsGrid?.length > 0 ) {

      return (
       <>

        <ChannelsGridItems
         gridData={channelsData}
         gridItemName={gridItemName}
         type={'channels'}
         rowColSm={rowColSm}
         rowColMd={rowColMd}
         rowColLg={rowColLg}
         rowColXl={rowColXl}
         listStyle={listStyle}
         listLayout={listLayout}
         cardLayout={cardLayout}
         clickableCard={clickableCard}
         footLinkTitle={footLinkTitle}
         footLinkViewChannel={footLinkViewChannel}
         footLinkManageChannel={footLinkManageChannel}
         companyLogo={companyLogo}
         pageType={pageType}
         utmParams={utmParams}
         imgHoverOverlay={imgHoverOverlay}
         dataBddTitle={dataBddTitle}
         dataBddDesc={dataBddDesc}
         dataBddSubs={dataBddSubs}
         dataBddTalksQnt={dataBddTalksQnt}
         baseUrl={baseUrl}
         priorityStatus={priorityStatus}
        />

        <LoadMore
         onClick={loadMoreBtn}
         loader={showLoadMoreBtn}
         showLoadMore={displayLoadMore}
         gridItemName={gridItemName}
        />
       </>
      );

    } else {
      <></>
    }

   })()}

  </div>

};

export default ChannelsGrid;
